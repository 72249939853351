import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit} from '@angular/core';
import { RoleDto, UserDto } from '../../../dto';
import { HttpInvoiceService } from '../../../http-invoice.service';
import { AppToastService } from 'src/app/components/toasts/app-toast.service';
import { catchError } from 'rxjs/operators';
import { HttpAdminService } from '../../../http-admin.service';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
	selector: 'app-edit-admin-role-modal',
	templateUrl: './edit-admin-role-modal.component.html',
	styleUrls: ['./edit-admin-role-modal.component.scss']
})
export class EditAdminRoleModalComponent implements OnInit {
	@Input() admin: UserDto;

	edit: UserDto;
	roles: RoleDto[];
	availableRoles: RoleDto[];

	add = faArrowRight;
	remove = faArrowLeft;

	constructor(
		public modal: NgbActiveModal, 
		private toastService: AppToastService, 
		private httpService: HttpAdminService,
		private spinner: NgxSpinnerService) { }

	ngOnInit(): void {
		this.edit = Object.assign({}, this.admin);

		this.availableRoles = [];
		this.roles = [];

		this.admin.roles.forEach(item => {
			this.roles.push({
				id: item.id,
				createdAt: item.createdAt,
				roleName: item.roleName
			});
		});

		this.httpService.getAllRoles().subscribe(item => {
			let roles = item;

			roles.forEach(availableRole => {
				if (this.admin.roles.filter(item => item.roleName === availableRole.roleName).length == 0) {
					this.availableRoles.push({
						id: availableRole.id,
						createdAt: availableRole.createdAt,
						roleName: availableRole.roleName
					});
				}
			})
		});		
	}

	addRole(role: RoleDto) {
		this.roles.push(role);

		this.availableRoles.forEach((value,index)=>{
			if(value==role) this.availableRoles.splice(index,1);
		});
	}

	removeRole(role: RoleDto) {
		this.roles.forEach((value,index)=>{
			if(value==role) this.roles.splice(index,1);
		});

		this.availableRoles.push(role);
	}

	save() {

		if (this.roles.length == 0) {
			this.toastService.showSecondary({ content: 'Please select at least one role for the admin' });
			return;
		}

		this.edit.roles = [];
		this.roles.forEach(item => {
			this.edit.roles.push({
				id: item.id,
				createdAt: item.createdAt,
				roleName: item.roleName
			});
		});

		this.spinner.show();
		this.httpService.editAdminRoles(this.edit)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error editing admin', error);
				this.toastService.showDanger({ content: 'Failed to edit admin: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			this.spinner.hide();
			if (!data.responseStatus) {
				this.toastService.showDanger({ content: 'Failed to edit admin: ' + data.responseStatusMessage });
				return;
			}

			this.toastService.showSuccess({ content: 'Admin saved successfully'});
			this.modal.close(true);
		});
	}

	cancel() {
		this.modal.close(false);
	}
}