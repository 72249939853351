import { HttpParams, HttpEvent } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractHttpService } from '../../service/http-abstract-service';
import { InvoiceDto, InvoicePayloadDto } from './dto';


@Injectable({
	providedIn: 'root'
})
export class HttpInvoiceService extends AbstractHttpService {

	constructor(http: HttpClient) {
		super(http, '/invoice');
	}

	getAll(): Observable<InvoicePayloadDto[]> {
		return super.get(`/list`);
	}
	getInvoice(invoiceId: number): Observable<InvoicePayloadDto> {
		return super.get(`/${invoiceId}/get`);
	}

	generateInvoice(invoiceId: number): Observable<Blob> {
		return super.getBlob(`/${invoiceId}/generate`);
	}

	addInvoice(invoice: InvoicePayloadDto): Observable<InvoicePayloadDto> {
		return super.post(`/save`, invoice);
	}

	markPaid(invoice: InvoiceDto): Observable<InvoicePayloadDto> {
		return super.post(`/markpaid`, invoice);
	}

}
