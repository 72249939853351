import { catchError, map } from 'rxjs/operators';
import { Observable, of, ReplaySubject, interval } from 'rxjs';
import { HttpAuthService } from './http-auth.service';
// src/app/auth/auth.service.ts
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppToastService } from '../components/toasts/app-toast.service';
import { SignInOutcome } from '../domain';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class AuthService {
	helper: JwtHelperService;
	authStateChange$ = new ReplaySubject<boolean>(1);
	timeToExpire$ = new ReplaySubject<number|'reset'>(0);
	authedState;

	constructor(
			public jwtHelper: JwtHelperService, 
			private httpAuthService: HttpAuthService,
			private toastService: AppToastService,
			private modalService: NgbModal
			) {
		this.helper = new JwtHelperService();

		interval(1000).subscribe(item => {
			const newState = this.isAuthenticated();
			if (this.authedState !== newState) {
				this.authedState = newState;
				this.authStateChange$.next(newState);
			}
			if (newState === true) {
				this.timeToExpire$.next(Math.round((this.helper.getTokenExpirationDate(this.getToken()).getTime() - new Date().getTime()) / 1000));
			}
		});
	}

	public isAuthenticated(): boolean {
		const storedToken = this.getToken();
		if (storedToken === undefined || storedToken == null) {
			return false;
		}
		if (this.helper.isTokenExpired(storedToken)) {
			this.signOut();
			return false;
		}
		return true;
	}

	public isVerified(): boolean {
		const decodedToken = this.helper.decodeToken(this.getToken());
		return decodedToken.verified;
	}

	public getUsername(): string {
		const decodedToken = this.helper.decodeToken(this.getToken());
		return decodedToken.username;
	}

	public hasAnyRole(roles: string[]): boolean {
		const role = this.helper.decodeToken(this.getToken()).role as string;
		const userRoles = role.split(",");
		
		for (let r of roles) {
			if (userRoles.indexOf(r) !== -1) {
				return true;
			}
		}

		return false;
	}

	signIn(username: string, password: string): Observable<SignInOutcome | HttpErrorResponse> {
		return this.httpAuthService.signIn(username, password).pipe(
			map(item => {
				console.log('Sign in', item);
				if (item.error) {
					return {
						success: false,
						error: item.error
					}
				}
				this.setToken(item.token);
				this.timeToExpire$.next('reset');
				return {
					success: true,
					error: null
				};
			}),
			catchError(error => {
				console.log('Failed', error);
				return of({
					success: false,
					error: error
				});
			})
		);
	}
	refreshToken() {
		return this.httpAuthService.refreshToken().pipe(
			map(item => {
				console.log('Success', item);
				this.setToken(item.token);
				return true;
			}),
			catchError(error => {
				console.log('Failed', error);
				return of(false);
			})
		);
	}
	signOut() {
		this.clearToken();
		this.modalService.dismissAll();
		this.authedState = false;
		this.authStateChange$.next(false);
	}

	private getToken(): string {
		return localStorage.getItem('auth_token');
	}
	private setToken(token: string) {
		localStorage.setItem('auth_token', token);
	}
	private clearToken() {
		localStorage.removeItem('auth_token');
	}

}
