import { Component, OnInit } from "@angular/core";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { AccountDetailsDto } from "../../dto";
import { UtilService } from "../../util.service";
import { catchError } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { HttpAccountService } from "../../http-accounts.service";


@Component({
	selector: 'app-invoice-list',
	templateUrl: './account-list.component.html',
	styleUrls: ['./account-list.component.scss']
})
export class AccountListComponent implements OnInit {
	accounts: AccountDetailsDto[];
	util = UtilService;

	constructor(
		private httpService: HttpAccountService, 
		private spinner: NgxSpinnerService,
		private toastService: AppToastService,
		private router: Router) {
	}

	ngOnInit(): void {
		this.fetchAllAccounts();
	}

	private fetchAllAccounts() {
		this.spinner.show();
		this.httpService.getAll()
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching accounts', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(items => {
			this.accounts = items;
			this.spinner.hide();
		});
	}

	viewAccount(account: AccountDetailsDto) {
		
	}
}