import { AdminAuthResponseDto } from './dto';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractHttpService } from './http-abstract-service';

@Injectable({
	providedIn: 'root'
})
export class HttpAuthService extends AbstractHttpService {

	constructor(http: HttpClient) {
		super(http, '/auth');
	}

	signIn(username: string, password: string): Observable<AdminAuthResponseDto> {
		return super.post(`/${username}/sign-in`, password);
	}
	refreshToken(): Observable<AdminAuthResponseDto> {
		return super.post(`/refresh`,{});
	}

}
