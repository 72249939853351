import { AppToastService, Toast } from './../app-toast.service';
import { Component, OnInit, TemplateRef } from '@angular/core';

@Component({
	selector: 'app-toasts',
	templateUrl: './toasts.component.html',
	styleUrls: ['./toasts.component.scss']
})
export class ToastsComponent implements OnInit {

	constructor(public toastService: AppToastService) { }

	ngOnInit(): void {
	}
	isTemplate(toast: Toast) {
		return toast.content instanceof TemplateRef;
	}

}
