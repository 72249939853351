<nav>
	<div class="nav nav-tabs mt-1" id="nav-tab" role="tablist">
		<button
			class="nav-link active" id="nav-info-tab" data-bs-toggle="tab" data-bs-target="#nav-info" type="button"
			role="tab" aria-controls="nav-info" name="tab-nav" (click)="activateTab($event)">Info</button>

		<div class="col text-end">
			<button class="btn btn-outline-secondary" (click)="goBack()"><fa-icon [icon]="back" size="sm" ></fa-icon></button>
		</div>
	</div>
</nav>
<div class="tab-content content-background" id="nav-tabContent">
	<div class="tab-pane fade show active" id="nav-info" role="tabpanel" aria-labelledby="nav-info-tab" name="tab-content">
		<ng-container *ngIf="isTabActive('nav-info')" [ngTemplateOutlet]="invoiceInfo"></ng-container>
	</div>
</div>
<ng-template #invoiceInfo><app-invoice-info [invoiceData]="invoicePayload" (reloadInvoice)="fetchInvoice()"></app-invoice-info></ng-template>