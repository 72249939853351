<app-top-bar></app-top-bar>

<div class="container-fluid">
	<div class="row">
		<main role="main" class="col">
			<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="large" color="#fff" type="ball-clip-rotate-multiple">
				<p style="font-size: 20px; color: white">Loading...</p>
			</ngx-spinner>
			<router-outlet></router-outlet>
		</main>
		<!--Spacer for footer version-->
		<div>&nbsp;</div>
	</div>
	<div class="fixed-bottom">
		<div class="float-end m-1 text-muted version">
			GUI: v{{version}}&nbsp; API: v{{getApiVersion()}}
		</div>
		
	</div>
</div>
