<main class="form-signin text-center body">
	<div class="card shadow-lg p-3 mb-5 bg-white rounded">
		<div class="card-header">
			<h4 class="mb-3 fw-normal">Invoice Management</h4>
		</div>
		<div class="card-body">
			
			<form #loginForm="ngForm">
				<div class="form-floating mb-1">
					<input type="text" id="floatingInput" class="form-control" placeholder="Username" [(ngModel)]="username" name="username" autocomplete="new">
					<label for="floatingInput">Username</label>
				</div>
				<div class="form-floating mb-3">
					<input type="password" id="floatingPassword" class="form-control" placeholder="Password" [(ngModel)]="password" name="username" autocomplete="new-password">
					<label for="floatingPassword">Password</label>
				</div>
				<div class="row" *ngIf="showFailed">
					<div class="col alert alert-danger">{{error ? error : 'Login failed'}}</div>
				</div>
		
				<button class="w-100 btn btn-lg btn-primary" type="submit" (click)="doSignIn()">SIGN IN</button>
			</form>
		</div>
	</div>
	
</main>