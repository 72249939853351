<nav class="navbar sticky-top flex-md-nowrap p-2 shadow">
	<div>
		<button *ngIf="this.authService.isVerified()" class="btn" type="button" (click)="open(content)">
			<span class="navbar-toggler-icon"></span>
		</button>
		<a class="navbar-brand col-md-3 col-lg-2 mr-0 px-3" href="#">Invoice Management</a>
		{{pageName}}
	</div>
	<div>
		<div class="row float-right">
			<div *ngIf="this.authService.isVerified()" class="col link">
				<fa-icon class="text-light" [icon]="profile"></fa-icon>&nbsp;<a  class="nav-link text-light" [routerLink]="['/invoice','profile']">{{username}}</a>
			</div>
			<div class="col link">
				<fa-icon [icon]="signOut"></fa-icon>&nbsp;<a class="nav-link" [routerLink]="['/']" (click)="authService.signOut()">Sign out</a>
			</div>
		</div>
	</div>
</nav>

<ng-template #content let-offcanvas>
	<div class="offcanvas-header">
		<h4 class="offcanvas-title" id="offcanvas-basic-title">Menu</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
	</div>
	<div class="offcanvas-body">
		<ng-container *ngFor="let pageGroup of pageGroups"> <!--let pageGroup of pageGroups | async-->
			<h6
				class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted text-uppercase">
				<span>{{pageGroup.title}}</span>
			</h6>

			<ul *ngFor="let page of pageGroup.pages" class="nav flex-column mb-2">
				<li class="nav-item">
					<a [routerLink]="['/invoice',page.path]" [routerLinkActive]="['active']" class="nav-link" (click)="offcanvas.dismiss('Cross click')">
						<fa-icon [icon]="page.icon"></fa-icon>
						{{page.name}}
					</a>
				</li>
			</ul>
		</ng-container>



	</div>
</ng-template>