import { HttpParams, HttpEvent } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractHttpService } from '../../service/http-abstract-service';
import { UserChangePasswordDto, AdminInvoiceResponseDto, RoleDto, UserDto } from './dto';


@Injectable({
	providedIn: 'root'
})
export class HttpUserService extends AbstractHttpService {

	constructor(http: HttpClient) {
		super(http, '/user');
	}

	getUserByUsername(username: string): Observable<UserDto> {
		return super.get(`/${username}/get`);
	}

	sendOtp(adminId: number): Observable<AdminInvoiceResponseDto> {
		return super.get(`/${adminId}/send-otp`);
	}

	verifyOtp(adminId: number, otp: number): Observable<AdminInvoiceResponseDto> {
		return super.get(`/${adminId}/${otp}/verify-otp`);
	}

	setPassword(username: string, currentPassword: string, newPassword: string, confirmPassword: string): Observable<AdminInvoiceResponseDto> {
		let payload: UserChangePasswordDto = {
			newPassword: newPassword,
			currentPassword: currentPassword,
			confirmPassword: confirmPassword
		}
		return super.post(`/${username}/set-password`, payload);
	}

	editUser(user: UserDto): Observable<AdminInvoiceResponseDto> {
		return super.post(`/${user.id}/edit`, user);
	}
}
