import { Injectable } from "@angular/core";
import { AuthService } from "src/app/service/auth.service";
import { InvoicePayloadDto, UserDto } from "./dto";

@Injectable({ providedIn: 'root' })
export class UtilService {

	static getInvoiceDetails(invoicePayload: InvoicePayloadDto): string {
		if (!invoicePayload) {
			return '';
		}

		return invoicePayload.property.addressLine1 + ' (' + invoicePayload.recipient.recipientName + ' ' + invoicePayload.recipient.recipientSurname + ')';
	}

	static generateUsername(user: UserDto): string {
		if (user.name && user.surname) {
			return (user.name.substring(0,1) + user.surname).toLowerCase();
		}
	}

}