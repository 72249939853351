import { Component, OnDestroy, OnInit } from "@angular/core";
import { AccountDetailsDto, InvoicePayloadDto, RecipientDto, RoleDto, UserDto, PropertyDetailsDto } from "../../dto";
import { HttpAdminService } from "../../http-admin.service";
import { faArrowLeft,faArrowRight,faFileText,faPeopleArrows } from '@fortawesome/free-solid-svg-icons';
import { catchError } from "rxjs/operators";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmModalComponent } from "../../components/confirm-modal/confirm-modal.component";
import { NgxSpinnerService } from "ngx-spinner";
import { UtilService } from "../../util.service";
import { Location } from "@angular/common";
import { HttpInvoiceService } from "../../http-invoice.service";
import { HttpAccountService } from "../../http-accounts.service";
import { HttpPropertyService } from "../../http-property.service";
import { HttpRecipientService } from "../../http-recipient.service";
import { forkJoin } from "rxjs";

@Component({
	selector: 'app-invoice-add',
	templateUrl: './invoice-add.component.html',
	styleUrls: ['./invoice-add.component.scss']
})
export class InvoiceAddComponent implements OnInit {

	invoice: InvoicePayloadDto;
	accounts: AccountDetailsDto[];
	properties: PropertyDetailsDto[];
	recipients: RecipientDto[];

	accountModel: AccountDetailsDto;
	propertyModel: PropertyDetailsDto;
	recipientModel: RecipientDto;

	back = faArrowLeft;
	add = faArrowRight;
	remove = faArrowLeft;
	newInvoice = faFileText;

	util = UtilService;

	constructor(
		private locationService: Location, 
		private httpService: HttpInvoiceService, 
		private httpAccountService: HttpAccountService, 
		private httpPropertyService: HttpPropertyService, 
		private httpRecipientService: HttpRecipientService, 
		private toastService: AppToastService,
		private modalService: NgbModal,
		private spinner: NgxSpinnerService,) {
	}

	ngOnInit(): void {
		this.invoice = {
			property: {},
			invoiceDetails: {},
			invoice: {},
			account: {},
			recipient: {},
		};
		this.fetchData();
	}

	goBack(): void {
		this.locationService.back()
	}

	resetForm() {
		this.invoice = {
		  property: {},
		  invoiceDetails: {},
		  invoice: {},
		  account: {},
		  recipient: {},
		};

		this.accountModel = null;
		this.propertyModel = null;
		this.recipientModel = null;
	  }

	fetchData() {
		this.spinner.show();
		forkJoin([this.httpAccountService.getAll(), this.httpPropertyService.getAll(), this.httpRecipientService.getAll()])
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching data', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(item => {
			this.spinner.hide();
			this.accounts = item[0];
			this.properties = item[1];
			this.recipients = item[2];
		});
	}
	
	addInvoice() {

		const modalRef = this.modalService.open(ConfirmModalComponent, { windowClass: "modal-confirm" });
		modalRef.componentInstance.title = 'Add Invoice';
		modalRef.componentInstance.text = "Please confirm you wish to add the Invoice.";

		modalRef.result.then((result) => {
			if (result === true) {
				this.doAddInvoice();
			}
		});
	}
	private doAddInvoice() {

		this.invoice.invoice.accountId = this.accountModel.id;
		this.invoice.invoice.propertyId = this.propertyModel.id;
		this.invoice.invoice.recipientId = this.recipientModel.id;

		this.spinner.show();
		this.httpService.addInvoice(this.invoice)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error adding admin', error);
				this.toastService.showDanger({ content: 'Failed to add invoice: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			console.log('data', data);
			this.spinner.hide();
			if (!data.responseStatus) {
				this.toastService.showDanger({ content: 'Failed to add invoice: ' + data.responseStatusMessage });
				return;
			}

			this.toastService.showSuccess({ content: 'Invoice saved successfully'});

			this.resetForm();
		});
	}

}