import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from "@angular/core";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { InvoiceDto, InvoicePayloadDto, InvoiceStatusEnum } from "../../../../dto";
import { ActivatedRoute } from "@angular/router";
import { catchError, concatMapTo } from "rxjs/operators";
import { faHourglassStart, faLock, faMap, faArrowLeft, faBan, faCheck, faUnlock, faEdit, faTimes, faQuestion, faPrint, faSimCard, faCashRegister, faClipboard, faStopwatch, faCertificate, faCircle, faBarcode, faCoins, faMoneyBill, faMoneyBillWave, faFilePdf, faFileInvoiceDollar, faCalendar, faCalendarPlus, faCalendarDays, faCalendarDay, faCircleInfo, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { Location } from "@angular/common";
import { UtilService } from "../../../../util.service";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthService } from "src/app/service/auth.service";
import { HttpInvoiceService } from "src/app/modules/invoice/http-invoice.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
	selector: 'app-invoice-info',
	templateUrl: './invoice-info.component.html',
	styleUrls: ['./invoice-info.component.scss']
})
export class InvoiceInfoComponent {

	invoicePayload: InvoicePayloadDto;
	@Output() reloadInvoice: EventEmitter<any> = new EventEmitter(); 
	
	paid = faMoneyBill;
	generate = faFilePdf;
	invoiceIcon = faFileInvoiceDollar;
	createdAtIcon = faCalendarPlus;
	calenderIcon = faCalendarDays;
	dueDateIcon = faCalendarDay;
	paidAtIcon = faCalendarCheck;
	statusIcon = faCircleInfo;

	util = UtilService;

	constructor(
		private httpService: HttpInvoiceService,
		private toastService: AppToastService, 
		private spinner: NgxSpinnerService) {
	}

	@Input() 
	set invoiceData(invoicePayload_: InvoicePayloadDto) {
		if (!invoicePayload_) {
			return;
		}
		
		this.invoicePayload = invoicePayload_;
	}

	getStatsCss(input: InvoicePayloadDto ) {
		switch (input.invoice.status.toString()) {
			case 'Overdue':
				return 'text-danger';
			case 'Pending':
				return 'text-warning';
			case 'Paid':
				return 'text-success';
		}
	}

	get statusCss() {
		switch (this.invoicePayload.invoice.status) {
			case InvoiceStatusEnum.Paid:
				return 'text-success';
			case InvoiceStatusEnum.Pending:
				return 'text-warning';
			case InvoiceStatusEnum.Overdue:
				return 'text-danger';
		}
	}

	public paidInvoice() {
		this.spinner.show();
		this.httpService.markPaid(this.invoicePayload.invoice)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error generating invoice', error);
				this.toastService.showDanger({ content: 'Failed to mark invoice as paid: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			console.log('data', data);
			this.spinner.hide();
			if (!data.responseStatus) {
				this.toastService.showDanger({ content: 'Failed to mark invoice as paid: ' + data.responseStatusMessage });
				return;
			}
			this.toastService.showSuccess({ content: 'Invoice marked as paid successfully'});
			this.reloadInvoice.emit();
		});
	}

	public generateInvoice() {
		this.spinner.show();
		this.httpService.generateInvoice(this.invoicePayload.invoiceDetails.invoiceId)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error generating invoice', error);
				this.toastService.showDanger({ content: 'Failed to generate invoice: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(items => {
			window.open(URL.createObjectURL(items));
			this.spinner.hide();
		});
	}

	get disablePaidButton(): boolean {
		var status = Object.values(InvoiceStatusEnum)[InvoiceStatusEnum.Paid];
		return this.invoicePayload.invoice.status === status;
	}
}