import { HttpParams, HttpEvent } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractHttpService } from '../../service/http-abstract-service';
import { PropertyDetailsDto } from './dto';


@Injectable({
	providedIn: 'root'
})
export class HttpPropertyService extends AbstractHttpService {

	constructor(http: HttpClient) {
		super(http, '/property');
	}

	getAll(): Observable<PropertyDetailsDto[]> {
		return super.get(`/list`);
	}
}
