const allowedActions = [
	{
		action: 'invoice-edit',
		role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']
	},
	{
		action: 'invoice-verify',
		role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']
	},
	{
		action: 'invoice-invalidate-auth',
		role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']
	},
	{
		action: 'invoice-transactions',
		role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']
	}
]

export const environment = {
	production: false,
	contextPath: '',
	allowedActions: allowedActions
};
