<ng-container *ngIf="invoicePayload">
	<div class="row d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center m-2 mb-0 pb-0">
		<div class="col">
			{{util.getInvoiceDetails(invoicePayload)}}
		</div>
		<div class="col text-end">	
			<div class="btn-group bg-white" role="group">
				<button class="btn btn-outline-secondary" 
					(click)="paidInvoice()" 
					type="button"
					[disabled]="disablePaidButton">
					Paid <fa-icon [icon]="paid" size="lg"></fa-icon>
				</button>
				<button class="btn btn-outline-secondary" 
					(click)="generateInvoice()" 
					type="button">
					Generate <fa-icon [icon]="generate" size="lg"></fa-icon>
				</button>
			</div>			
		</div>
	</div>
	<div class="row">
		<div class="col">
			<div class="card m-3 shadow-lg bg-white rounded stats">
				<div class="card-body">
					<table class="table table-borderless text-center">
						<thead>
							<tr>
								<th><fa-icon [icon]="invoiceIcon" class="text-primary fs-4" [title]="'Invoice ID'"></fa-icon></th>
								<th><fa-icon [icon]="createdAtIcon" class="text-primary fs-4" [title]="'Created At'"></fa-icon></th>
								<th><fa-icon [icon]="paidAtIcon" class="text-primary fs-4" [title]="'Paid At'"></fa-icon></th>
								<th><fa-icon [icon]="calenderIcon" class="text-primary fs-4" [title]="'Invoice Date'"></fa-icon></th>
								<th><fa-icon [icon]="dueDateIcon" class="text-primary fs-4" [title]="'Due Date'"></fa-icon></th>
								<th><fa-icon [icon]="statusIcon" class="text-primary fs-4" [title]="'Status'"></fa-icon></th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td >{{invoicePayload.invoice.id}}</td>
								<td >{{invoicePayload.invoice.createdAt | date:'yyyy-MM-dd HH:mm'}}</td>
								<td>{{ invoicePayload.invoice.paidAt ? (invoicePayload.invoice.paidAt | date:'yyyy-MM-dd HH:mm') : 'N/A' }}</td>
								<td >{{invoicePayload.invoice.invoiceDate | date:'yyyy-MM-dd'}}</td>
								<td >{{invoicePayload.invoice.invoiceDueDate | date:'yyyy-MM-dd'}}</td>
								<td [class]="getStatsCss(invoicePayload)">{{invoicePayload.invoice.status}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col">
			<div class="card m-3 shadow-lg bg-white rounded">
				<div class="card-header">Property Details</div>
				<div class="card-body">
					<table class="table table-responsive table-borderless">
						<tbody>
							<tr>
								<td>Address Line 1</td>
								<td>{{invoicePayload.property.addressLine1}}</td>
							</tr>
							<tr>
								<td>Address line 2</td>
								<td>{{invoicePayload.property.addressLine2}}</td>
							</tr>
							<tr>
								<td>Suburb</td>
								<td>{{invoicePayload.property.suburb}}</td>
							</tr>
							<tr>
								<td>City</td>
								<td>{{invoicePayload.property.city}}</td>
							</tr>
							<tr>
								<td>Province</td>
								<td>{{invoicePayload.property.province}}</td>
							</tr>
							<tr>
								<td>Country</td>
								<td>{{invoicePayload.property.country}}</td>
							</tr>
							<tr>
							<td>Postal Code</td>
								<td>{{invoicePayload.property.postalCode}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="col">
			<div class="card m-3 shadow-lg bg-white rounded">
				<div class="card-header">Invoice Details</div>
				<div class="card-body">
					<table class="table table-responsive table-borderless">
						<tbody>
							<tr>
								<td>Created At</td>
								<td>{{invoicePayload.invoiceDetails.createdAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
							</tr>
							<tr>
								<td>Rent Price</td>
								<td>{{invoicePayload.invoiceDetails.rentPrice | currency: 'R'}}</td>
							</tr>
							<tr>
								<td>Electricity</td>
								<td>{{invoicePayload.invoiceDetails.electricityPrice | currency: 'R'}}</td>
							</tr>
							<tr>
								<td>Electricity Units</td>
								<td>{{invoicePayload.invoiceDetails.electricityUnit}}</td>
							</tr>
							<tr>
								<td>Water</td>
								<td>{{invoicePayload.invoiceDetails.waterPrice | currency: 'R'}}</td>
							</tr>
							<tr>
								<td>Water Units</td>
								<td>{{invoicePayload.invoiceDetails.waterUnit}}</td>
							</tr>
							<tr>
								<td>Total Price</td>
								<td>{{invoicePayload.invoiceDetails.totalPrice | currency: 'R'}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col">
			<div class="card m-3 shadow-lg bg-white rounded">
				<div class="card-header">Recipient Details</div>
				<div class="card-body">
					<table class="table table-responsive table-borderless">
						<tbody>
							<tr>
								<td>First Name</td>
								<td>{{invoicePayload.recipient.recipientName}}</td>
							</tr>
							<tr>
								<td>Surname</td>
								<td>{{invoicePayload.recipient.recipientSurname}}</td>
							</tr>
							<tr>
								<td>Email</td>
								<td>{{invoicePayload.recipient.recipientEmail}}</td>
							</tr>
							<tr>
								<td>Number</td>
								<td>{{invoicePayload.recipient.recipientNumber}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="col">
			<div class="card m-3 shadow-lg bg-white rounded">
				<div class="card-header">Account Details</div>
				<div class="card-body">
					<table class="table table-responsive table-borderless">
						<tbody>
							<tr>
								<td>Bank Name</td>
								<td>{{invoicePayload.account.bankName}}</td>
							</tr>
							<tr>
								<td>Branch Name</td>
								<td>{{invoicePayload.account.branchName}}</td>
							</tr>
							<tr>
								<td>Branch Code</td>
								<td>{{invoicePayload.account.branchCode}}</td>
							</tr>
							<tr>
								<td>Account Holder</td>
								<td>{{invoicePayload.account.accountHolder}}</td>
							</tr>
							<tr>
								<td>Account Number</td>
								<td>{{invoicePayload.account.accountNumber}}</td>
							</tr>
							<tr>
								<td>Account Type</td>
								<td>{{invoicePayload.account.accountType}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</ng-container>
