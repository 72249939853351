<ngb-toast
	*ngFor="let toast of toastService.toasts"
	[class]="toast.classname"
	[autohide]="toast.autohide"
	[delay]="toast.delay || 5000"
	(hidden)="toastService.hide(toast)"
	(click)="toastService.clicked(toast)"
	>


	<ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
		<ng-template [ngTemplateOutlet]="toast.content"></ng-template>
	</ng-template>

	<ng-template #text>{{ toast.content }}</ng-template>

</ngb-toast>
