<div class="pt-3 pb-2 mb-3">
	<div class="shadow-lg p-3 mb-5 bg-white rounded table-responsive">
		<table *ngIf="properties" class="table table-hover">
			<thead>
				<tr>
					<th>ID</th>
					<th>Created At</th>
					<th>Address Line 1</th>
					<th>Address Line 2</th>
					<th>Suburb</th>
					<th>City</th>
					<th>Province</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let property of properties" (click)="viewProperty(property)">
					<th scope="row">{{property.id}}</th>
					<td>{{property.createdAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
					<td>{{property.addressLine1}}</td>
					<td>{{property.addressLine2}}</td>
					<td>{{property.suburb}}</td>
					<td>{{property.city}}</td>
					<td>{{property.province}}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
