import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit} from '@angular/core';
import { AppToastService } from 'src/app/components/toasts/app-toast.service';
import { catchError } from 'rxjs/operators';

@Component({
	selector: 'app-confirm-modal',
	templateUrl: './confirm-modal.component.html',
	styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {
	@Input() title: string;
	@Input() text: string;
	@Input() additionalText: string;
	@Input() confirmButton: string = 'Confirm';
	@Input() cancelButton: string = 'Cancel';

	constructor(public modal: NgbActiveModal) { }

	confirm() {
		this.modal.close(true);
	}
	cancel() {
		this.modal.close(false);
	}
}