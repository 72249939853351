import { Component, OnInit } from "@angular/core";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { InvoicePayloadDto } from "../../dto";
import { HttpInvoiceService } from "../../http-invoice.service";
import { UtilService } from "../../util.service";
import { catchError } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { faBan, faCheck, faHourglass, faPlusCircle } from "@fortawesome/free-solid-svg-icons";


@Component({
	selector: 'app-invoice-list',
	templateUrl: './invoice-list.component.html',
	styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent implements OnInit {
	invoices: InvoicePayloadDto[];
	util = UtilService;

	add = faPlusCircle;
	paid = faCheck;
	pending = faHourglass;

	constructor(
		private httpService: HttpInvoiceService, 
		private spinner: NgxSpinnerService,
		private toastService: AppToastService,
		private router: Router) {
	}

	ngOnInit(): void {
		this.fetchAllInvoices();
	}

	addInvoice() {
		this.router.navigate(['/invoice','add-invoice']);
	}

	private fetchAllInvoices() {
		this.spinner.show();
		this.httpService.getAll()
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching invoices', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(items => {
			this.invoices = items;
			this.spinner.hide();
		});
	}

	viewInvoice(invoice: InvoicePayloadDto) {
		this.router.navigate(['/invoice','view', invoice.invoiceDetails.invoiceId]);
	}
}