<div class="pt-3 pb-2 mb-3">
	<div class="shadow-lg p-3 mb-5 bg-white rounded table-responsive">
		<table *ngIf="accounts" class="table table-hover">
			<thead>
				<tr>
					<th>ID</th>
					<th>Created At</th>
					<th>Bank Name</th>
					<th>Branch Name</th>
					<th>Branch Code</th>
					<th>Account Holder</th>
					<th>Account Number</th>
					<th>Account Type</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let account of accounts" (click)="viewAccount(account)">
					<th scope="row">{{account.id}}</th>
					<td>{{account.createdAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
					<td>{{account.bankName}}</td>
					<td>{{account.branchName}}</td>
					<td>{{account.branchCode}}</td>
					<td>{{account.accountHolder}}</td>
					<td>{{account.accountNumber}}</td>
					<td>{{account.accountType}}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
