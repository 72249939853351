<div class="row d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
	<div class="col">	
		<div class="btn-group" role="group">
			<button class="btn btn-outline-secondary" 
				(click)="addInvoice()" 
				type="button">
				Add <fa-icon [icon]="add" size="lg"></fa-icon>
			</button>
		</div>			
	</div>
</div>
<div class="pt-3 pb-2 mb-3">
	<div class="shadow-lg p-3 mb-5 bg-white rounded table-responsive">
		<table *ngIf="invoices" class="table table-hover">
			<thead>
				<tr>
					<th>ID</th>
					<th>Created At</th>
					<th>Property</th>
					<th>Rent</th>
					<th>Electricity</th>
					<th>Water</th>
					<th>Total</th>
					<th>Status</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let invoice of invoices" (click)="viewInvoice(invoice)">
					<th scope="row">{{invoice.invoiceDetails.id}}</th>
					<td>{{invoice.invoiceDetails.createdAt | date:'yyyy-MM-dd HH:mm:ss'}}</td>
					<td>{{invoice.property.addressLine1}}</td>
					<td>{{invoice.invoiceDetails.rentPrice | currency: 'R'}}</td>
					<td>{{invoice.invoiceDetails.electricityPrice | currency: 'R'}}</td>
					<td>{{invoice.invoiceDetails.waterPrice | currency: 'R'}}</td>
					<td>{{invoice.invoiceDetails.totalPrice | currency: 'R'}}</td>
					<td><div [ngSwitch]="invoice.invoice.status">
						<fa-icon [icon]="paid" size="lg" *ngSwitchCase="'Paid'" class="text-success" title="Paid"></fa-icon>
						<fa-icon [icon]="pending" size="lg" *ngSwitchCase="'Pending'" class="text-warning" title="Pending"></fa-icon>
					</div></td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
