import { map, first } from 'rxjs/operators';
import { HttpConfigService } from '../service/http-config.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class FeatureGuard implements CanActivate {
	constructor(private configService: HttpConfigService) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
		const out = this.configService.isFeatureEnabled(route.data.feature);
		if (out != null) {
			return out;
		}
		return this.configService.forceRefresh().pipe(
			map(item => this.configService.isFeatureEnabled(route.data.feature))
		);
	}
}
