import { Component, Input, OnInit } from "@angular/core";
import { AuthService } from "src/app/service/auth.service";
import { faArrowLeft,faArrowRight,faPeopleArrows, faEye, faEyeSlash, faUser, faPhone, faMailBulk, faMailReply, faEnvelope, faEdit } from '@fortawesome/free-solid-svg-icons';
import { UserDto } from "src/app/modules/invoice/dto";
import { AdminActionService } from "src/app/modules/invoice/admin.service";
import { HttpAdminService } from "src/app/modules/invoice/http-admin.service";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { catchError } from "rxjs/operators";
import { Location } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpUserService } from "src/app/modules/invoice/http-user.service";

@Component({
	selector: 'app-user-profile',
	templateUrl: './user-profile.component.html',
	styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

	user: UserDto;
	edit: UserDto;
	confirmPasswordModel: string;
	newPasswordModel: string;
	currentPasswordModel: string;

	currentPasswordField = 'password';
	passwordField = 'password';
	confirmPasswordField = 'password';

	passwordHidden = faEye;
	passwordVisible = faEyeSlash;

	currentPasswordFieldIcon = this.passwordHidden;
	passwordFieldIcon = this.passwordHidden;
	confirmPasswordFieldIcon = this.passwordHidden;

	newAdmin=faPeopleArrows;
	changeProfile=faEdit;
	back = faArrowLeft;
	userIcon=faUser;
	tel=faPhone;
	email=faEnvelope;

	username: string;

	constructor(
		public authService: AuthService,
		private httpService: HttpUserService,
		private toastService: AppToastService,
		private spinner: NgxSpinnerService,
		private location: Location,) {
	}

	ngOnInit(): void {
		this.init();
	}

	private init() {
		this.username = this.authService.getUsername().toLowerCase();
		this.spinner.show();
		this.httpService.getUserByUsername(this.username)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching data', error);
				this.toastService.showDanger({ content: 'Failed to fetch data: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(item => {
			this.spinner.hide();
			this.user = item;

			this.edit = Object.assign({}, this.user);
		});	
	}

	changePassword() {
		
		if (this.newPasswordModel !== this.confirmPasswordModel) {
			this.toastService.showSecondary({ content: 'Passwords do not match' });
			return;
		}

		this.spinner.show();
		this.httpService.setPassword(this.user.username, this.currentPasswordModel, this.newPasswordModel, this.confirmPasswordModel)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error setting user password', error);
				this.toastService.showDanger({ content: 'Failed to set password: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			this.spinner.hide();
			if (!data.responseStatus) {
				this.toastService.showDanger({ content: 'Failed to set password: ' + data.responseStatusMessage });
				return;
			}

			this.toastService.showSuccess({ content: 'Password changed successfully'});

			this.init();
			this.currentPasswordModel=null;
			this.newPasswordModel=null;
			this.confirmPasswordModel=null;
			this.currentPasswordField = 'password';
			this.passwordField = 'password';
			this.confirmPasswordField = 'password';
			this.currentPasswordFieldIcon = this.passwordHidden;
			this.passwordFieldIcon = this.passwordHidden;
			this.confirmPasswordFieldIcon = this.passwordHidden;
		});
	}

	toggleCurrentPassword() {
		if (this.currentPasswordField === 'text') {
			this.currentPasswordField = 'password';
			this.currentPasswordFieldIcon = this.passwordHidden;
			return;
		}
		this.currentPasswordField = 'text';
		this.currentPasswordFieldIcon = this.passwordVisible;
	}

	togglePassword() {
		if (this.passwordField === 'text') {
			this.passwordField = 'password';
			this.passwordFieldIcon = this.passwordHidden;
			return;
		}
		this.passwordField = 'text';
		this.passwordFieldIcon = this.passwordVisible;
	}

	toggleConfirmPassword() {
		if (this.confirmPasswordField === 'text') {
			this.confirmPasswordField = 'password';
			this.confirmPasswordFieldIcon = this.passwordHidden;
			return;
		}
		this.confirmPasswordField = 'text';
		this.confirmPasswordFieldIcon = this.passwordVisible;
	}

	get disableChangePassword(): boolean {
		return this.newPasswordModel == null || 
			this.currentPasswordModel == null ||
			this.confirmPasswordModel == null;
	}

	changeDetails() {

		this.spinner.show();
		this.httpService.editUser(this.edit)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error editing user password', error);
				this.toastService.showDanger({ content: 'Failed to update details: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			this.spinner.hide();
			if (!data.responseStatus) {
				this.toastService.showDanger({ content: 'Failed to update details: ' + data.responseStatusMessage });
				return;
			}

			this.toastService.showSuccess({ content: 'Details changed successfully'});

			this.init();
		});
	}

	get disableChangeDetails(): boolean {
		return this.edit.name == null || 
			this.edit.surname == null ||
			this.edit.msisdn == null ||
			this.edit.email == null;
	}

	goBack(): void {
		this.location.back();
	}
}