import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit} from '@angular/core';
import { UserDto } from '../../../dto';
import { HttpInvoiceService } from '../../../http-invoice.service';
import { AppToastService } from 'src/app/components/toasts/app-toast.service';
import { catchError } from 'rxjs/operators';
import { HttpAdminService } from '../../../http-admin.service';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
	selector: 'app-edit-admin-modal',
	templateUrl: './edit-admin-modal.component.html',
	styleUrls: ['./edit-admin-modal.component.scss']
})
export class EditAdminModalComponent implements OnInit {
	@Input() admin: UserDto;

	edit: UserDto;

	add = faArrowRight;
	remove = faArrowLeft;

	constructor(
		public modal: NgbActiveModal, 
		private toastService: AppToastService, 
		private httpService: HttpAdminService,
		private spinner: NgxSpinnerService) { }

	ngOnInit(): void {
		this.edit = Object.assign({}, this.admin);
	}

	save() {
		if (!this.edit.name) {
			this.toastService.showSecondary({ content: 'Please enter the name of the admin' });
			return;
		}

		if (!this.edit.surname) {
			this.toastService.showSecondary({ content: 'Please enter the surname of the admin' });
			return;
		}

		if (!this.edit.msisdn) {
			this.toastService.showSecondary({ content: 'Please enter the MSISDN of the admin' });
			return;
		}

		if (!this.edit.email) {
			this.toastService.showSecondary({ content: 'Please enter the email of the admin' });
			return;
		}

		this.spinner.show();
		this.httpService.editAdmin(this.edit)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error editing admin', error);
				this.toastService.showDanger({ content: 'Failed to edit admin: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			this.spinner.hide();
			if (!data.responseStatus) {
				this.toastService.showDanger({ content: 'Failed to edit admin: ' + data.responseStatusMessage });
				return;
			}

			this.toastService.showSuccess({ content: 'Admin saved successfully'});
			this.modal.close(true);
		});
	}

	cancel() {
		this.modal.close(false);
	}
}