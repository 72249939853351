import { Component, Input, OnInit } from "@angular/core";
import { AuthService } from "src/app/service/auth.service";
import { faArrowLeft,faArrowRight,faPeopleArrows, faEye, faEyeSlash, faL } from '@fortawesome/free-solid-svg-icons';
import { UserDto } from "src/app/modules/invoice/dto";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { catchError } from "rxjs/operators";
import { HttpConfigService } from "src/app/service/http-config.service";
import { HttpUserService } from "src/app/modules/invoice/http-user.service";

@Component({
	selector: 'app-user-set-password',
	templateUrl: './user-set-password.component.html',
	styleUrls: ['./user-set-password.component.scss']
})
export class UserSetPasswordComponent implements OnInit {

	screen : 'otp' | 'password' = 'otp';

	user: UserDto;
	confirmPasswordModel: string;

	passwordField = 'password';
	confirmPasswordField = 'password';

	passwordHidden = faEye;
	passwordVisible = faEyeSlash;

	passwordFieldIcon = this.passwordHidden;
	confirmPasswordFieldIcon = this.passwordHidden;

	newAdmin=faPeopleArrows;

	otpModel: string;

	disableVerify = false;
	disableResend = true;

	moveToPassword = false;

	constructor(
		public authService: AuthService,
		private httpService: HttpUserService,
		private configService: HttpConfigService,
		private toastService: AppToastService,) {
	}

	ngOnInit(): void {
		this.httpService.getUserByUsername(this.authService.getUsername()).subscribe(item => {
			this.user = item;
			this.user.password = null;

			if (this.isOtpScreen) {
				this.sendOtp();
			}
		});	
		
	}

	get isOtpScreen() {
		if (this.moveToPassword) {
			return false;
		}
		return this.configService.isOtpWithPassword();
	}

	get isPasswordScreen() {
		if (this.moveToPassword) {
			return true;
		}
		return !this.configService.isOtpWithPassword();
	}

	save() {
		if (this.user.password !== this.confirmPasswordModel) {
			this.toastService.showSecondary({ content: 'Passwords do not match' });
			return;
		}

		this.httpService.setPassword(this.user.username, null, this.user.password, this.confirmPasswordModel)
		.pipe(
			catchError(error => {
				console.log('Error setting admin password', error);
				this.toastService.showDanger({ content: 'Failed to set password: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			console.log('data', data);
			if (!data.responseStatus) {
				this.toastService.showDanger({ content: 'Failed to set password: ' + data.responseStatusMessage });
				return;
			}

			this.toastService.showSuccess({ content: 'Password set successfully. You may now login'});
			this.authService.signOut();
		});
	}

	togglePassword() {
		if (this.passwordField === 'text') {
			this.passwordField = 'password';
			this.passwordFieldIcon = this.passwordHidden;
			return;
		}
		this.passwordField = 'text';
		this.passwordFieldIcon = this.passwordVisible;
	}

	toggleConfirmPassword() {
		if (this.confirmPasswordField === 'text') {
			this.confirmPasswordField = 'password';
			this.confirmPasswordFieldIcon = this.passwordHidden;
			return;
		}
		this.confirmPasswordField = 'text';
		this.confirmPasswordFieldIcon = this.passwordVisible;
	}

	get disableSave(): boolean {
		return this.user.password == null || this.confirmPasswordModel == null
			|| (this.user.password !== this.confirmPasswordModel);
	}

	get disableOtp(): boolean {
		if (this.disableVerify) {
			return true;
		}		
		return this.otpModel == null || (this.otpModel + "").length !== 5;
	}

	verifyOtp() {
		this.disableVerify = true;
		this.disableResend = true;
		this.httpService.verifyOtp(this.user.id, +this.otpModel)
		.pipe(
			catchError(error => {
				console.log('Error verifying OTP', error);
				this.toastService.showDanger({ content: 'Failed to verify OTP: ' + (error.error ? error.error.error : error.message) });
				this.disableVerify = false;
				this.disableResend = false;
				throw error;
			})
		)
		.subscribe(item => {
			if (!item.responseStatus) {
				this.toastService.showDanger({ content: 'OTP verification failed: ' + item.responseStatusMessage});
			} else {
				this.moveToPassword = true;
			}
			this.disableResend = false;
			this.disableVerify = false;
		});
	}

	resendOtp() {
		this.otpModel = null;
		this.sendOtp();
	}

	private sendOtp() {
		this.disableResend = true;
		this.httpService.sendOtp(this.user.id)
		.pipe(
			catchError(error => {
				console.log('Error sending OTP', error);
				this.toastService.showDanger({ content: 'Failed to send OTP: ' + (error.error ? error.error.error : error.message) });
				this.disableResend = false;
				throw error;
			})
		)
		.subscribe(item => {
			if (!item.responseStatus) {
				this.toastService.showDanger({ content: 'An error occurred sending the OTP. Please try again: ' + item.responseStatusMessage});
			}
			this.disableResend = false;
		});
	}
}