import { AuthService } from '../../service/auth.service';
import { Component, OnInit } from '@angular/core';
import { SignInOutcome } from 'src/app/domain';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
	selector: 'app-sign-in',
	templateUrl: './sign-in.component.html',
	styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
	username = '';
	password = '';
	showFailed = false;
	error: string;

	constructor(private authService: AuthService) { }

	ngOnInit(): void {
		this.username = '';
		this.password = '';
	}

	doSignIn() {
		this.showFailed = false;
		this.error = null;
		this.authService.signIn(this.username, this.password).subscribe(item => {
			this.password = undefined;

			if (item.error) {
				this.showFailed = true;
				this.error = item.error.statusText ? item.error.statusText : item.error;
			}
		});
	}
}
