<div class="modal-header">
	<h4 class="modal-title" id="modal-title">{{title}}</h4>
	<button type="button" class="btn-close" aria-describedby="modal-title" (click)="cancel()">
	</button>
</div>
<div class="modal-body">
	<p>{{text}}</p>
	<p *ngIf="additionalText" class="small">{{additionalText}}</p>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-outline-secondary" (click)="cancel()">{{cancelButton}}</button>
	<button type="button" class="btn btn-success" (click)="confirm()">{{confirmButton}}</button>
</div>