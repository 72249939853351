import { HttpParams, HttpEvent } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractHttpService } from '../../service/http-abstract-service';
import { RecipientDto } from './dto';


@Injectable({
	providedIn: 'root'
})
export class HttpRecipientService extends AbstractHttpService {

	constructor(http: HttpClient) {
		super(http, '/recipient');
	}

	getAll(): Observable<RecipientDto[]> {
		return super.get(`/list`);
	}
}
