import { Routes, RouterModule, Route } from '@angular/router';
import { FileSizePipe } from '../../components/file-size.pipe';
import { CopyClipboardDirective } from '../../components/copy-clipboard.directive';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureGuard } from 'src/app/utils/feature.guard';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { AdminListComponent } from './pages/admin-list/admin-list.component';
import { EditAdminModalComponent } from './pages/admin-list/edit-admin-modal/edit-admin-modal.component';
import { EditAdminRoleModalComponent } from './pages/admin-list/edit-admin-role-modal/edit-admin-role-modal.component';
import { AdminAddComponent } from './pages/admin-add/admin-add.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { GeoapifyGeocoderAutocompleteModule } from '@geoapify/angular-geocoder-autocomplete';
import { TimeagoModule } from 'ngx-timeago';
import { UserProfileComponent } from 'src/app/pages/user-profile/user-profile.component';
import { environment } from 'src/environments/environment';
import { faAddressBook, faBell, faBriefcaseClock, faCheck, faClock, faCreditCard, faFileInvoiceDollar, faFileText, faFileVideo, faGear, faGears, faHome, faIdBadge, faKey, faLayerGroup, faLocation, faLocationPin, faMagnifyingGlassPlus, faPeopleArrows, faPeopleGroup, faSearchPlus, faTicket, faTools, faVideo, faVideoCamera, faVideoSlash, faWrench } from "@fortawesome/free-solid-svg-icons";
import { Page, PageGroup } from 'src/app/domain';
import { FileDropzoneDirective } from './components/file-dropzone-directive/file-dropzone-directive.component';
import { WebcamModule } from 'ngx-webcam';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { InvoiceListComponent } from './pages/invoice-list/invoice-list.component';
import { ViewInvoiceComponent } from './pages/invoice-list/view-invoice/view-invoice.component';
import { InvoiceInfoComponent } from './pages/invoice-list/view-invoice/invoice-info/invoice-info.component';
import { PropertyListComponent } from './pages/property-list/property-list.component';
import { InvoiceAddComponent } from './pages/invoice-add/invoice-add.component';
import { AccountListComponent } from './pages/account-list/account-list.component';
import { RecipientListComponent } from './pages/recipient-list/recipient-list.component';

//must specify all routes and 2 additional: profile and **

export const pages: PageGroup[] = [
	{
		title: 'Property',
		pages: [
			{ path: 'property', component: PropertyListComponent, feature: 'Invoice', name: 'All Properties', routerLink: 'list', icon: faHome, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'] },
		]
	},
	{
		title: 'Account',
		pages: [
			{ path: 'Account', component: AccountListComponent, feature: 'Invoice', name: 'All Accounts', routerLink: 'list', icon: faCreditCard, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'] },
		]
	},
	{
		title: 'Recipient',
		pages: [
			{ path: 'Recipient', component: RecipientListComponent, feature: 'Invoice', name: 'All Recipients', routerLink: 'list', icon: faAddressBook, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'] },
		]
	},
	{
		title: 'Invoices',
		pages: [
			{ path: 'list', component: InvoiceListComponent, feature: 'Invoice', name: 'All Invoices', routerLink: 'list', icon: faLayerGroup, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'] },
			{ path: 'view/:invoiceId', component: ViewInvoiceComponent, feature: 'invoice', name: 'View Invoice', routerLink: null, icon: null, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'] },
			{ path: 'add-invoice', component: InvoiceAddComponent, feature: 'Invoice', name: 'Add invoice', routerLink: 'add-invoice', icon: faFileInvoiceDollar, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'] },
		]
	},
	{
		title: 'Administration',
		pages: [
			{ path: 'admins', component: AdminListComponent, feature: 'Invoice', name: 'All Admins', routerLink: 'admins', icon: faPeopleGroup, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'] },
			{ path: 'add-admin', component: AdminAddComponent, feature: 'Invoice', name: 'Add Admin', routerLink: 'add-admin', icon: faPeopleArrows, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'] },
		]
	},
	{
		title: 'Profile',
		pages: [
			{ path: 'profile', component: UserProfileComponent, feature: 'Invoice', name: 'Profile', routerLink: null, icon: faLocation, role: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_TECHNICIAN'] }
		]
	}
	// {
	// 	title: 'Settings',
	// 	pages: [
	// 		{ path: 'settings', component: SettingsListComponent, feature: 'Invoice', name: 'Settings', routerLink: 'locations', icon: faGear, role: ['ROLE_SUPER_ADMIN'] }
	// 	]
	// },

	
];

function getRoutes(): Route[] {
	var out: Route[] = [];
	pages.forEach(item => {
		item.pages.forEach(i => {
			out.push(
				{
					path: i.path, 
					component: i.component, 
					canActivate: [FeatureGuard], 
					data: { 
						feature: 'Invoice', 
						page: i 
					}
				}
			);
		});
	});
	return out;
}

// const routes: Routes = [
// 	//Will need to change this to a component to view admins
// 	{ path: 'view-admin/:adminId', component: AdminListComponent, canActivate: [FeatureGuard], data: { feature: 'Invoice', pageName: 'All Admins' } },
// 	{ path: 'add-admin', component: AdminAddComponent, canActivate: [FeatureGuard], data: { feature: 'Invoice', pageName: 'Add Admin' } },
// 	{ path: 'profile', component: UserProfileComponent, canActivate: [FeatureGuard], data: { feature: 'Invoice', pageName: 'Profile' } },
// ];


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		FontAwesomeModule,
		NgbModule,
		NgSelectModule,
		// RouterModule,
		RouterModule.forChild(getRoutes()),
		TimeagoModule.forRoot()
	],
	declarations: [
		AccountListComponent,
		InvoiceAddComponent,
		InvoiceListComponent,
		ViewInvoiceComponent,
		InvoiceInfoComponent,
		PropertyListComponent,
		RecipientListComponent,
		ConfirmModalComponent,
		AdminListComponent,
		EditAdminModalComponent,
		EditAdminRoleModalComponent,
		AdminAddComponent,
		FileDropzoneDirective,
	],
	exports: [RouterModule]
})
export class InvoiceModule{ 
	static getPages() {
		return pages;
	}

	// constructor() {
	// 	const newRoutes = [];
	// 	var routes = environment.paths.map(item => {
	// 		return {
	// 			path: item.path, 
	// 			component: item.component, 
	// 			canActivate: [FeatureGuard], 
	// 			data: { 
	// 				feature: 'Invoice', 
	// 				pageName: item.pageName 
	// 			}
	// 		};
	// 	});
	// 	console.log(routes);

	// 	RouterModule.forChild(routes);
	// }
}
