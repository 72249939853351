import { Injectable, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { catchError } from "rxjs/operators";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { ConfirmModalComponent } from "./components/confirm-modal/confirm-modal.component";
import { UserDto } from "./dto";
import { HttpAdminService } from "./http-admin.service";
import { HttpInvoiceService } from "./http-invoice.service";
import { EditAdminModalComponent } from "./pages/admin-list/edit-admin-modal/edit-admin-modal.component";
import { EditAdminRoleModalComponent } from "./pages/admin-list/edit-admin-role-modal/edit-admin-role-modal.component";

export class AdminAction {
	action: 'Edit' | 'Disable' | 'Unblock' | 'ReEnable';
}

@Injectable({ providedIn: 'root' })
export class AdminActionService {

	@Output() onActionSuccess: EventEmitter<AdminAction> = new EventEmitter(); 

	constructor(
		private httpService: HttpAdminService,
		private modalService: NgbModal,
		private toastService: AppToastService,){}

	editAdmin(admin: UserDto) {
		const modalRef = this.modalService.open(EditAdminModalComponent, { windowClass: "modal-edit-invoice" });
		modalRef.componentInstance.admin = admin;

		modalRef.result.then((result) => {
			if (result === true) {
				this.onActionSuccess.emit({action: 'Edit'});
			}
		});
	}

	editAdminRoles(admin: UserDto) {
		const modalRef = this.modalService.open(EditAdminRoleModalComponent, { windowClass: "modal-edit-invoice" });
		modalRef.componentInstance.admin = admin;

		modalRef.result.then((result) => {
			if (result === true) {
				this.onActionSuccess.emit({action: 'Edit'});
			}
		});
	}

	disableAdmin(admin: UserDto) {
		const modalRef = this.modalService.open(ConfirmModalComponent, { windowClass: "modal-confirm" });
		modalRef.componentInstance.title = 'Disable Admin';
		modalRef.componentInstance.text = 'Please confirm you wish to disable the selected admin.';

		modalRef.result.then((result) => {
			if (result === true) {
				this.changeAdminStatus(admin, 'Disable');
			}
		});
	}

	unblockAdmin(admin: UserDto) {
		const modalRef = this.modalService.open(ConfirmModalComponent, { windowClass: "modal-confirm" });
		modalRef.componentInstance.title = 'Unblock Admin';
		modalRef.componentInstance.text = 'Please confirm you wish to unblock the selected admin.';

		modalRef.result.then((result) => {
			if (result === true) {
				this.changeAdminStatus(admin, 'Unblock');
			}
		});
	}

	reEnableAdmin(admin: UserDto) {
		const modalRef = this.modalService.open(ConfirmModalComponent, { windowClass: "modal-confirm" });
		modalRef.componentInstance.title = 'Re-enable Admin';
		modalRef.componentInstance.text = 'Please confirm you wish to re-enable the selected admin.';

		modalRef.result.then((result) => {
			if (result === true) {
				this.changeAdminStatus(admin, 'ReEnable');
			}
		});
	}

	private changeAdminStatus(admin: UserDto, action: 'Disable' | 'Unblock' | 'ReEnable') {
		this.httpService.changeAdminStatus(admin.id, action)
		.pipe(
			catchError(error => {
				console.log('Error changing admin status', error);
				this.toastService.showDanger({ content: 'Failed to ' + action + ' admin: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			if (!data.responseStatus) {
				this.toastService.showDanger({ content: 'Failed to ' + action + ' admin: ' + data.responseStatusMessage });
				return;
			}

			this.toastService.showSuccess({ content: 'Action performed successfully'});
			this.onActionSuccess.emit({action: action});
		});
	}
}