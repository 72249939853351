<div class="pt-3 pb-2 mb-3">
	<div class="shadow-lg p-3 mb-5 bg-white rounded table-responsive">
		<table *ngIf="recipients" class="table table-hover">
			<thead>
				<tr>
					<th>ID</th>
					<th>Recipient Name</th>
					<th>Recipient Surname</th>
					<th>Recipient Email</th>
					<th>Recipient Number</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let recipient of recipients" (click)="viewRecipient(recipient)">
					<th scope="row">{{recipient.id}}</th>
					<td>{{recipient.recipientName}}</td>
					<td>{{recipient.recipientSurname}}</td>
					<td>{{recipient.recipientEmail}}</td>
					<td>{{recipient.recipientNumber}}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
