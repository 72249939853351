import { SafeUrl } from '@angular/platform-browser';
import { AbstractDto } from 'src/app/service/dto';

export class UserDto extends AbstractDto {
	id?: number;
	createdAt?: Date;
	username?: string;
	password?: string;
	userStatus?: 'New' | 'Verified' | 'Blocked' | 'Disabled' = 'New';
	name?: string;
	surname?: string;
	email?: string;
	msisdn?: string;
	roles?: RoleDto[];
}

export class RoleDto extends AbstractDto {
	id: number;
	createdAt: Date;
	roleName: string;
}

export class UserRoleDto extends AbstractDto {
	id: number;
	createdAt: Date;
	adminUserId: number;
	adminRoleId: number;
}

export class UserChangePasswordDto extends AbstractDto {
	currentPassword: string;
	newPassword: string;
	confirmPassword: string;
}

export class InvoiceSettingDto extends AbstractDto {
	id: number;
	createdAt: Date;
	updatedAt: Date;
	name: string;
	value: string;
	description: string;
	inputType?: string;
}

export class AdminInvoiceResponseDto extends AbstractDto {

}

/*
======================================================================================================================
WORK BELOW THIS COMMENT
======================================================================================================================
*/

export class AccountDetailsDto {
	id?: number;
	createdAt?: Date;
	bankName?: string;
	branchName?: string;
	branchCode?: number;
	accountHolder?: string;
	accountNumber?: string;
	accountType?: string;
}

export class InvoiceDetailsDto {
	id?: number;
	createdAt?: Date;
	invoiceId?: number;
	rentPrice?: number;
	electricityPrice?: number;
	electricityUnit?: number;
	electricityOpenUnit?: number;
	electricityOpenDate?: Date;
	electricityCloseUnit?: number;
	electricityCloseDate?: Date;
	waterPrice?: number;
	waterUnit?: number;
	waterOpenUnit?: number;
	waterOpenDate?: Date;
	waterCloseUnit?: number;
	waterCloseDate?: Date;
	totalPrice?: number;
}

export class RecipientDto {
	id?: number;
	recipientName?: string;
	recipientSurname?: string;
	recipientEmail?: string;
	recipientNumber?: string;
}

export class PropertyDetailsDto {
	id?: number;
	createdAt?: Date;
	addressLine1?: string;
	addressLine2?: string;
	suburb?: string;
	city?: string;
	province?: string;
	postalCode?: string;
	country?: string;
	unitNumber?: number;
}

export enum InvoiceStatusEnum {
	Pending,
	Paid,
	Overdue
}

export class InvoiceDto {
	id?: number;
	createdAt?: Date;
	pdfGeneratedAt?: Date;
	paidAt?: Date;
	invoiceDate?: Date;
	invoiceDueDate?: Date;
	propertyId?: number;
	recipientId?: number;
	accountId?: number;
	status?: InvoiceStatusEnum;
}

export class InvoicePayloadDto extends AbstractDto {
	property?: PropertyDetailsDto;
	invoiceDetails?: InvoiceDetailsDto;
	invoice?: InvoiceDto;
	account?: AccountDetailsDto;
	recipient?: RecipientDto;
}