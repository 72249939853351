<div class="row">
	<div class="col col-md-10 col-lg-6 mx-auto">
		<!-- Price Card -->
		<div class="card m-3 shadow-lg bg-white rounded">
			<div class="card-header">
				<div class="row">
					<div class="col pt-1"><fa-icon [icon]="newInvoice" class="text-secondary"></fa-icon>&nbsp;New Invoice</div>
					<div class="col text-end">
						<button class="float-end btn btn-outline-secondary" (click)="goBack()"><fa-icon [icon]="back" size="sm" ></fa-icon></button>
					</div>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-lg-6 mb-3 mx-auto input-group">
						<span class="input-group-text">Rent Price</span>
						<input type="number" class="form-control" id="rentPrice" aria-describedby="rentPrice" name="rentPrice" [(ngModel)]="invoice.invoiceDetails.rentPrice" >
						<div class="input-group-append">
							<span class="input-group-text">R</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<!-- Electricity Card -->
		<div class="card m-3 shadow-lg bg-white rounded">
			<div class="card-header">
				<div class="row">
					<div class="col pt-1">Electricity Details</div>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-lg-6 mb-3 mx-auto input-group">
                        <span class="input-group-text">Electricity Price</span>
                        <input type="number" step="0.1" max="20" class="form-control" id="electricityPrice" aria-describedby="electricityPrice" name="electricityPrice" [(ngModel)]="invoice.invoiceDetails.electricityPrice" >
                        <div class="input-group-append">
                            <span class="input-group-text">R</span>
                        </div>
                    </div>
                    <div class="col-lg-6 mb-3 mx-auto input-group">
                        <span class="input-group-text">Electricity Unit</span>
                        <input type="number" max="20" class="form-control" id="electricityUnit" aria-describedby="electricityUnit" name="electricityUnit" [(ngModel)]="invoice.invoiceDetails.electricityUnit" >
                    </div>
                    <div class="col-lg-6 mb-3 mx-auto input-group">
                        <span class="input-group-text">Electricity Open Unit</span>
                        <input type="number" max="20" class="form-control" id="electricityOpenUnit" aria-describedby="electricityOpenUnit" name="electricityOpenUnit" [(ngModel)]="invoice.invoiceDetails.electricityOpenUnit" >
                    </div>
                    <div class="col-lg-6 mb-3 mx-auto input-group">
                        <span class="input-group-text">Electricity Open Date</span>
                        <input type="date" class="form-control" id="electricityOpenDate" aria-describedby="electricityOpenDate" name="electricityOpenDate" [(ngModel)]="invoice.invoiceDetails.electricityOpenDate" 
                        >
                    </div>
                    <div class="col-lg-6 mb-3 mx-auto input-group">
                        <span class="input-group-text">Electricity Close Unit</span>
                        <input type="number" max="20" class="form-control" id="electricityCloseUnit" aria-describedby="electricityCloseUnit" name="electricityCloseUnit" [(ngModel)]="invoice.invoiceDetails.electricityCloseUnit" >
                    </div>
                    <div class="col-lg-6 mb-3 mx-auto input-group">
                        <span class="input-group-text">Electricity Close Date</span>
                        <input type="date" class="form-control" id="electricityCloseDate" aria-describedby="electricityCloseDate" name="electricityCloseDate" [(ngModel)]="invoice.invoiceDetails.electricityCloseDate" >
                    </div>
				</div>
			</div>
		</div>
		
		<!-- Water Card -->
		<div class="card m-3 shadow-lg bg-white rounded">
			<div class="card-header">
				<div class="row">
					<div class="col pt-1">Water Details</div>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-lg-6 mb-3 mx-auto input-group">
                        <span class="input-group-text">Water Price</span>
                        <input type="number" step="0.1" max="20" class="form-control" id="waterPrice" aria-describedby="waterPrice" name="waterPrice" [(ngModel)]="invoice.invoiceDetails.waterPrice" >
                        <div class="input-group-append">
                            <span class="input-group-text">R</span>
                        </div>
                    </div>
                    <div class="col-lg-6 mb-3 mx-auto input-group">
                        <span class="input-group-text">Water Unit</span>
                        <input type="number" max="20" class="form-control" id="waterUnit" aria-describedby="waterUnit" name="waterUnit" [(ngModel)]="invoice.invoiceDetails.waterUnit" >
                    </div>
                    <div class="col-lg-6 mb-3 mx-auto input-group">
                        <span class="input-group-text">Water Open Unit</span>
                        <input type="number" max="20" class="form-control" id="waterOpenUnit" aria-describedby="waterOpenUnit" name="waterOpenUnit" [(ngModel)]="invoice.invoiceDetails.waterOpenUnit" >
                    </div>
                    <div class="col-lg-6 mb-3 mx-auto input-group">
                        <span class="input-group-text">Water Open Date</span>
                        <input type="date" class="form-control" id="waterOpenDate" aria-describedby="waterOpenDate" name="waterOpenDate" [(ngModel)]="invoice.invoiceDetails.waterOpenDate" >
                    </div>
                    <div class="col-lg-6 mb-3 mx-auto input-group">
                        <span class="input-group-text">Water Close Unit</span>
                        <input type="number" max="20" class="form-control" id="waterCloseUnit" aria-describedby="waterCloseUnit" name="waterCloseUnit" [(ngModel)]="invoice.invoiceDetails.waterCloseUnit" >
                    </div>
                    <div class="col-lg-6 mb-3 mx-auto input-group">
                        <span class="input-group-text">Water Close Date</span>
                        <input type="date" class="form-control" id="waterCloseDate" aria-describedby="waterCloseDate" name="waterCloseDate" [(ngModel)]="invoice.invoiceDetails.waterCloseDate" >
                    </div>
				</div>
			</div>
		</div>
		
		<!-- Details Card -->
		<div class="card m-3 shadow-lg bg-white rounded">
			<div class="card-header">
				<div class="row">
					<div class="col pt-1">Details</div>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-lg-6 mb-3 mx-auto input-group">
						<span class="input-group-text">Account</span>
						<select name="account" class="form-control" id="account" [(ngModel)]="accountModel">
					<option *ngFor="let account of accounts" [ngValue]="account">{{account.bankName}} - {{account.accountNumber}}</option>
				</select>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-6 mb-3 mx-auto input-group">
				<span class="input-group-text">Property</span>
				<select name="property" class="form-control" id="property" [(ngModel)]="propertyModel">
					<option *ngFor="let property of properties" [ngValue]="property">{{property.addressLine1}}</option>
				</select>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-6 mb-3 mx-auto input-group">
				<span class="input-group-text">Recipient</span>
				<select name="recipient" class="form-control" id="recipient" [(ngModel)]="recipientModel">
					<option *ngFor="let recipient of recipients" [ngValue]="recipient">{{recipient.recipientName}} {{recipient.recipientSurname}}</option>
				</select>
			</div>
		</div>
	</div>
	<div class="card-footer">
		<button type="button" class="btn btn-success" (click)="addInvoice()">Save</button>
	</div>
</div>