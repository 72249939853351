import { Component, OnInit } from "@angular/core";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { catchError } from "rxjs/operators";
import { AppToastService } from "src/app/components/toasts/app-toast.service";
import { InvoicePayloadDto } from "../../../dto";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "src/app/service/auth.service";
import { environment } from "src/environments/environment";
import { HttpInvoiceService } from "../../../http-invoice.service";

@Component({
	selector: 'app-view-invoice',
	templateUrl: './view-invoice.component.html',
	styleUrls: ['./view-invoice.component.scss']
})
export class ViewInvoiceComponent implements OnInit {
	invoiceId: number;
	invoicePayload: InvoicePayloadDto;
	back = faArrowLeft;

	activeTab: string = 'nav-info';

	constructor(private location: Location, 
		private activeRoute: ActivatedRoute, 
		private httpService: HttpInvoiceService,
		private spinner: NgxSpinnerService,
		private authService: AuthService,
		private toastService: AppToastService) {}

	ngOnInit(): void {
		this.fetchInvoice();
	}

	public fetchInvoice() {
		this.activeRoute.paramMap.subscribe(map => this.invoiceId = +map.get('invoiceId'));
		this.spinner.show();
		this.httpService.getInvoice(this.invoiceId)
		.pipe(
			catchError(error => {
				this.spinner.hide();
				console.log('Error fetching invoice', error);
				this.toastService.showDanger({ content: 'Failed to fetch invoice: ' + (error.error ? error.error.error : error.message) });
				throw error;
			})
		)
		.subscribe(data => {
			this.spinner.hide();
			if (!data.responseStatus) {
				this.toastService.showDanger({ content: 'Failed to fetch invoice: ' + data.responseStatusMessage });
				return;
			}
			this.invoicePayload = data;
		});
	}

	activateTab($event) {
		const tabButtons = document.getElementsByName("tab-nav") as NodeListOf<HTMLElement>;
		var controlTarget;
		tabButtons.forEach(item => {
			item.className = 'nav-link';
			if (item.id === $event.target.id) {
				item.className = 'nav-link active';
				controlTarget = document.getElementById($event.target.id).getAttribute('aria-controls');
			}
		});

		const tabContent = document.getElementsByName("tab-content") as NodeListOf<HTMLElement>;
		tabContent.forEach(item => {
			item.className = 'tab-pane fade';
			if (item.id === controlTarget) {
				this.activeTab = controlTarget;
				item.className = 'tab-pane fade show active';
			}
		});
	}

	isTabActive(id: string) {
		return id === this.activeTab;
	}

	showTab(action: string) {
		return this.authService.hasAnyRole(environment.allowedActions.filter(item => item.action === action)[0].role);
	}

	goBack(): void {
		this.location.back()
	}

}