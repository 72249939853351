import { environment } from './../../environments/environment';
import { HttpClient, HttpEvent, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';

const BASE_PATH = '/api/invoice';
export abstract class AbstractHttpService {
	constructor(private http: HttpClient, private context: string) { }

	protected get<T>(uri: string): Observable<T> {
		return this.http.get<T>(`${this.getPathPrefix()}${uri}`);
	}
	protected getBase64<T>(uri: string): Observable<string> {
		return this.http.get(`${this.getPathPrefix()}${uri}`, { responseType: 'text' });
	}
	protected getBlob<T>(uri: string): Observable<Blob> {
		return this.http.get(`${this.getPathPrefix()}${uri}`, { responseType: 'blob' });
	}
	protected post<T>(uri: string, payload: any, parameters?: HttpParams): Observable<T> {
		if (parameters) {
			return this.http.post<T>(`${this.getPathPrefix()}${uri}`, payload, { params: parameters });
		}
		return this.http.post<T>(`${this.getPathPrefix()}${uri}`, payload);
	}
	protected doUploadFile(uri: string, payload: FormData): Observable<HttpEvent<object>> {
		return this.http.post(`${this.getPathPrefix()}${uri}`, payload, { reportProgress: true, observe: 'events' });
	}
	protected doDownloadFile(uri: string): Observable<HttpEvent<Blob>> {
		return this.http.get(`${this.getPathPrefix()}${uri}`, { responseType: 'blob', reportProgress: true, observe: 'events' });
	}
	protected doDownloadFileSimple(uri: string): Observable<Blob> {
		return this.http.get(`${this.getPathPrefix()}${uri}`, { responseType: 'blob' });
	}
	protected delete(uri: string): Observable<any> {
		return this.http.delete(`${this.getPathPrefix()}${uri}`);
	}
	public getPathPrefix(): string {
		return environment.contextPath + BASE_PATH + this.context;
	}
}
